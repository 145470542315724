import React from "react";
import emailjs from "emailjs-com";
import "./ContactForm.css";
import Swal from "sweetalert2";
// import ReCAPTCHA from "react-google-recaptcha";

const ContactFrom = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_kwkvxxi", "template_16frebv", e.target, "CSHGH3lh2s4yRQUrv")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    e.target.reset();

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your massege has been sent",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  return (
    <div className="contact-container">
      <form onSubmit={sendEmail}>
        <h4 className="text-center contact-me-header">Send Email</h4>
        <div className="input-area mb-3">
          <label htmlFor="name">Your Fullname</label>
          <i className="form-icon fa-solid fa-user"></i>
          <input
            id="name"
            name="name"
            type="text"
            className="form-control name"
            required
            placeholder=" Your fullname.."
          />
        </div>
        <div className="input-area mb-3">
          <label htmlFor="email">Email address</label>
          <i className="form-icon fa-regular fa-envelope"></i>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control email"
            required
            placeholder="  name@example.com"
          />
        </div>
        <div className="input-area mb-3">
          <label htmlFor="phone">Phone Number</label>
          <i className="form-icon fa-solid fa-square-phone"></i>
          <input
            id="phone"
            className="form-control phone"
            type="number"
            name="phone"
            pattern="[0-9]{5} [0-9]{2} [0-9]{2} [0-9]{2}"
            required
            placeholder="  01603552022"
          ></input>
        </div>
        <div className="mb-3 input-area">
          <label htmlFor="message">Messages</label>
          <textarea
            id="message"
            name="message"
            className="form-control message"
            rows="3"
            required
            placeholder="Write your messages"
          ></textarea>
          <button id="send-button" name="submit" type="submit" className="form-control submit my-2">
            Send
          </button>
          {/* <ReCAPTCHA
            sitekey="6Leicg4pAAAAAO1dPuF3cD0z-0XHy9mmrZBowtOl" // Google reCAPTCHA'dan alınan site anahtarı
            onChange={(value) => console.log("Captcha değeri:", value)}
          /> */}
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;

import React from "react";
import "./WheelChair.css";
import wheel1 from "../images/wheel1.jpeg";
import wheel2 from "../images/wheel2.jpeg";
import CallUs from "./CallUs";
import ContactFrom from "./ContactForm";

const WheelChair = () => {
  return (
    <div className="position-relative">
      <div className="wheel-bg-img"></div>
      {/* <p className="wheel-info-text-on-img">
        Flights and taxi arrivals all monitored and coordinated for the easiest
        travel
      </p> */}
      <div className="text-center">
        <h3 className="text-center mt-5 mb-3">
        Are You Looking For A Taxi That is Wheelchair Friendly? <i className="fa-brands fa-accessible-icon px-2"></i>
        </h3>
        <p className="text-justify mb-4 px-5 wheel-info">
        You've come to the right place! Rose taxis is a local taxi firm that has vehicles available for wheelchair users, pushchairs, electrical scooters and those that need ramp access or additional safety measures.
        </p>
        <img className="wheel-bttom-plane-img" src={wheel1} />
      </div>
      <h3 className="text-center mt-5">Information and Help</h3>
      <div className="wheel-contact-us w-90 d-flex">
        <CallUs />
        <ContactFrom />
      </div>
    </div>
  );
};

export default WheelChair;

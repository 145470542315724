import React from "react";
import "./Home.css";
import logoBorder from "../images/logo-border.png";
import mycar1 from "../images/mycar1.png";
import mycar2 from "../images/mycar2.png";
import CallUs from "./CallUs";
import ContactFrom from "./ContactForm";

const Home = () => {
  return (
    <div className="home-container">
      <div className="bg-img mb-3">
        <span className="rose-taxi-norwich-home">
          <span className="rose-text">Rose</span> Taxi Norwich
        </span>
        <div className="home-img-texts ms-5 ">
          <img className="home-logo-border mb-3 mt-5" src={logoBorder} />
          <h1 className="text-center">Rose Taxi for a safe travel</h1>
          <p>
            We’re centrally located in Norwich within close proximity to the airport, Bus Station
            and Norwich Train Station where we can offer an efficient, reliable taxi service to all
            of Norfolk and Suffolk’s venues, hotels & tourist attractions.
          </p>
        </div>
      </div>
      <hr className="my-5 w-50 m-auto" />
      <div className="p-4">
        <h1 className="text-center">Do you know?</h1>
        <p className="home-plus-info mb-5">
          All of our taxis are equipped with the very latest range of disabled access aids for ease
          of use for all wheelchair customers, which enables us to provide a professional and safe
          taxi service to our many valued customers.
        </p>
        <div className="mycars-container d-flex justify-content-between align-items-center px-5">
          <img className="mycar-img mycar1" src={mycar1} />
          <img className="mycar-img mycar2" src={mycar2} />
        </div>

        <h1 className="text-center mb-3 mt-5">Here are the areas we serve!</h1>
        <div className="serveces-group d-flex align-items-center justify-content-around">
          <ul className="list-group-one">
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>AirPort Transfer
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Hospital Appointmens
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Business Travel
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Personal Travel
            </li>
          </ul>
          <ul className="list-group-two">
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Wedding and Parties
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>School Contracts
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Local & Long Journeys
            </li>
            <li className="list-group-item">
              <i className="fa-solid fa-car-side me-2"></i>Account Jobs
            </li>
          </ul>
        </div>
        <p className="text-center mt-3">
          To read more about our services, please{" "}
          <a href="/contact" className="click-here-for-serveces">
            CLICK HERE
          </a>
        </p>
      </div>
      <hr className="my-5 w-50 m-auto" />
      <h1 className="text-center mb-3">Information and Help</h1>
      <div className="home-contact-us w-90 d-flex">
        <CallUs />
        <ContactFrom />
      </div>
    </div>
  );
};

export default Home;

import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MyNavbar from "./components/MyNavbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Airport from "./components/Airport";
import Banner from "./components/Banner";
import WheelChair from "./components/WheelChair";


const App = () => {
  return (
    <Router>
      <MyNavbar />
      <Banner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/airport" element={<Airport />} />
        <Route path="/services" element={<Services />} />
        <Route path="/wheelchair" element={<WheelChair />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

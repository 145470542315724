import { useState } from "react";
import "./MyNavbar.css";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="navbar-container" expand="md">
      <NavbarBrand>
        <a href="/">
          <img className="logo" src={logo} width={180} />
        </a>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink to="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact/">Contact</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/airport/">Airport Transfers</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/wheelchair">Wheelchair</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/services">All Services</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            {/* <DropdownToggle nav caret>
              Options
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu> */}
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default MyNavbar;

import React from "react";
import callUsImg from "../images/call-us.png";

import "./CallUs.css";

const CallUs = () => {
  const handleCallButtonClick = () => {
    const phoneNumber = "01603552022";
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className="call-us-container d-flex flex-column align-items-center">
      <p className="d-flex align-items-center mb-0 fs-2">
        <i className="fa-solid fa-phone-volume me-3"></i>01 603 55
        <span className="text-96">20</span>22
      </p>

      <img className="call-us-img" src={callUsImg} />
      <button className="call-us-button " onClick={handleCallButtonClick}>
        <i className="fa-solid fa-phone-volume me-2"></i>Now Call{" "}
        <b>
          {" "}
          Rose <span className="text-us">Taxi</span>
        </b>
      </button>
    </div>
  );
};

export default CallUs;

import React from "react";
import logo from "../images/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className={`footer p-3 text-white d-flex justify-content-around align-items-center`}>
        <div className="social-media d-flex justify-content-around align-items-center ">
          <div className="my-number d-flex align-items-center social-m-link">
            <i className="fa-solid fa-mobile-screen-button me-2 "></i>{" "}
            <span className="d-flex align-items-center">
              01603 55 <b className="text-danger fs-6 px-1"> 20 </b> 22
            </span>
          </div>
          <div className="email d-flex align-items-center  social-m-link">
            <i className="fa-solid fa-envelope me-2 "></i>
            <span className="d-flex align-items-center">info@rosetravel.co.uk</span>
          </div>
          <div className="guthub d-flex align-items-center ">
            <a
              className="text-white text-decoration-none social-m-link d-flex align-items-center "
              href="#"
            >
              <i className="fa-brands fa-instagram me-2"></i>
              Instegram
            </a>
          </div>
          <div className="facebook d-flex align-items-center ">
            <a
              className="text-white text-decoration-none social-m-link d-flex align-items-center "
              href="#"
            >
              <i className="fa-brands fa-facebook me-2"></i>
              Facebook
            </a>
          </div>
          <div className="linkedin d-flex align-items-center ">
            <a
              className="text-white text-decoration-none social-m-link d-flex align-items-center "
              href="#"
            >
              <i className="fa-brands fa-linkedin me-2 "></i>
              Linkedin
            </a>
          </div>
        </div>
        <p className="text-center copyright">&copy; Copyright 2023 Rose Travel</p>
      </div>
      <img className="home-logo-border" src={logo} />
    </div>
  );
};

export default Footer;

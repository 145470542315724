import React from "react";
import CallUs from "./CallUs";
import ContactFrom from "./ContactForm";
import "./Contact.css";
import RoseMap from "./RoseMap";

const Contact = () => {
  return (
    <div className="pt-1">
      <h2 className="text-center mt-4 mb-3 w-90">You Can Contact Us</h2>
      <p className="text-center mb-5 px-4">
        If you want, call us directly, or send us a message and we will call you.
      </p>
      <div className="all-contact-info">
        <p className="contact-item phone">
          <i className="fa-solid fa-square-phone px-2"></i>
          01603 55 <b className="text-danger fs-6 px-1"> 20 </b> 22
        </p>
        <p className="contact-item email">
          <i className="fa-regular fa-envelope px-2"></i>
          info@rosetravel.co.uk
        </p>
        <p className="contact-item address">
          <i className="fa-solid fa-map-location-dot px-2"></i>34 Russell Street Norwich NR2 4QT
        </p>
      </div>
      <div className="contact-us w-90 d-flex">
        <CallUs />
        <ContactFrom />
      </div>
      <h3 className="text-center my-3">
        Where do you find us<i className="fa-solid fa-map-location-dot px-2"></i>
      </h3>
      <RoseMap />
      <p className="my-3 text-center ">
        <b>Our address:</b> 34 Russell Street Norwich NR2 4QT
      </p>
    </div>
  );
};

export default Contact;

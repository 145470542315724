// Map.js
import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import "./RoseMap.css";
import locIcon from "../images/location-icon.png";

const Map = () => {
  const address = "34 Russell Street Norwich NR2 4QT";

  useEffect(() => {
    const getCoordinates = async () => {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            address
          )}&key=4db0732a9045467889efd9f1d15c6ffa 
`
        );

        // Koordinatları al
        const coordinates = response.data.results[0].geometry;
        // Harita oluştur
        const map = L.map("map").setView(
          [coordinates.lat, coordinates.lng],
          13
        );
        // Harita katmanını ekle
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "© OpenStreetMap contributors",
        }).addTo(map)
        // Noktayı haritaya ekle
        L.marker([coordinates.lat, coordinates.lng], {
          icon: L.icon({
            iconUrl: locIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
          }),
        }).addTo(map);
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };
    getCoordinates();
  }, [address]);

  return <div id="map" style={{ height: "400px", width: "80%" }}></div>;
};

export default Map;

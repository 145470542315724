import React from "react";
import "./Banner.css";

const Banner = () => {
  const handleCallButtonClick = () => {
    const phoneNumber = "01603552022";
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className="banner">
      <p>
        01603 55 <b className="text-danger px-1"> 20 </b> 22
      </p>
      <button className="banner-call-us-button " onClick={handleCallButtonClick}>
        <i className="fa-solid fa-phone-volume me-2"></i>Call{" "}
        <b>
          Rose <span className="banner-text-us">Taxi</span>
        </b>
      </button>
    </div>
  );
};

export default Banner;

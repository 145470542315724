import React from "react";
import "./Services.css";
import airport from "../images/airport.png";
import hospital from "../images/hospital.png";
import personal from "../images/personal.png";
import wedding from "../images/wedding.png";
import account from "../images/account.png";
import journey from "../images/journey.png";
import school from "../images/school.png";
import wheelchair from "../images/wheelchair.png";
import CallUs from "./CallUs";
import ContactFrom from "./ContactForm";

const Services = () => {
  const servecesData = [ 
    {
      name: "AirPort Transfer",
      info: "Flights and taxi arrivals all monitored and coordinated for the easiest travel",
      divStyle: {
        background: `url(${airport}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "Wheelchair Accessible",
      info: "Suitable for wheelchair users, strollers, electric scooters and those needing ramp access.",
      divStyle: {
        background: `url(${wheelchair}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "Hospital Appointmens",
      info: "You can contact us for all your hospital appointments.",
      divStyle: {
        background: `url(${hospital}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "School Contracts",
      info: "We make agreements with schools for regular transportation",
      divStyle: {
        background: `url(${school}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "Business & Personal Travel",
      info: "You can safely reach your business meetings, programs or wherever you want to go with us.",
      divStyle: {
        background: `url(${personal}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    // {
    //   name: "Personal Travel",
    //   info: "You can reach the place you personally want to go safely with us.",
    //   divStyle: {
    //     background: `url(${personal}) no-repeat center center`,
    //     WebkitBackgroundSize: "cover",
    //     MozBackgroundSize: "cover",
    //     OBackgroundSize: "cover",
    //     backgroundSize: "cover",
    //   },
    // },
    {
      name: "Wedding and Parties",
      info: "Contact us if you are getting married or having a party",
      divStyle: {
        background: `url(${wedding}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "Local & Long Journeys",
      info: "We are always ready for your short distance and long distance trips",
      divStyle: {
        background: `url(${journey}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
    {
      name: "Account Jobs",
      info: "Safe,secure and priority bookings for you and your staff available for all your business travel.",
      divStyle: {
        background: `url(${account}) no-repeat center center`,
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      },
    },
  ];
  return (
    <div className="services-container p-4">
      <div className="row">
        <h2 className="mb-5 mt-2 text-center">
          What types of services do we offer to you?
        </h2>
        {servecesData.map((item, index) => (
          <div key={index} className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{item.name}</h5>
                <p className="card-text">{item.info}</p>
                <div className="bg-card-img position-relative " style={item.divStyle}>
            <span className="rose-taxi-norwich">Rose Taxi Norwich</span>

                </div>
              </div>
            </div>
          </div>
        ))}
        <h3 className="text-center mt-5">
          Contact us now <i className="fa-solid fa-lightbulb px-2"></i>
        </h3>
        <div className="services-contact-us w-90 d-flex">
          <CallUs />
          <ContactFrom />
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import "./Airport.css";
import plane2 from "../images/plane-2.jpg";
import CallUs from "./CallUs";
import ContactFrom from "./ContactForm";

const Airport = () => {
  return (
    <div className="position-relative">
      <div className="airport-bg-img"></div>
      <p className="airport-info-text-on-img">
        Flights and taxi arrivals all monitored and coordinated for the easiest
        travel
      </p>
      <div className="text-center">
        <h3 className="text-center mt-5 mb-3">
          Airport Transfers <i className="fa-solid fa-plane-departure px-2"></i>
        </h3>
        <p className="text-justify mb-4 px-5 airport-info">
          Easily book your airport travel online by email or by phone. Ensuring
          hassle free journeys we monitor your flight so the taxi arrival can be
          accommodated specifically for you.
        </p>
        <img className="airport-bttom-plane-img" src={plane2} />
      </div>
      <h3 className="text-center mt-5">Information and Help</h3>
      <div className="airport-contact-us w-90 d-flex">
        <CallUs />
        <ContactFrom />
      </div>
    </div>
  );
};

export default Airport;
